//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard, XDown } from '@/components'
import { Empty } from 'ant-design-vue'
import { getOrgPage, sysOrgDelete, getOrgTree, sysOrgExport } from '@/api/modular/system/orgManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XDown,
    XCard,
    STable,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '機構名稱',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '備註',
          dataIndex: 'remark'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return getOrgPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      // 搜索的三個參數
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        key: 'id'
      },
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    }
  },
  created () {
    this.getOrgTree()
    if (this.hasPerm('sysOrg:edit') || this.hasPerm('sysOrg:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
     */
    getOrgTree () {
      getOrgTree(Object.assign(this.queryParam)).then(res => {
        this.treeLoading = false
        if (!res.success) {
          return
        }
        this.orgTree = res.data
        this.queryParam.parentId = this.orgTree[0].id
        // 全部展開，上面api方法提供的不生效，先用此方法
        for (var item of res.data) {
          // eslint-disable-next-line eqeqeq
          if (item.parentId == 0) {
            this.defaultExpandedKeys.push(item.id)
          }
        }
        this.$refs.table.refresh()
      })
    },
    /**
     * 單個刪除
     */
    singleDelete (record) {
      const param = [{ 'id': record.id }]
      this.sysOrgDelete(param)
    },
    /**
     * 批量刪除
     */
    batchDelete () {
      const paramIds = this.selectedRowKeys.map((d) => {
        return { 'id': d }
      })
      this.sysOrgDelete(paramIds)
    },
    /**
     * 批量導出
     */
    batchExport () {
      sysOrgExport().then((res) => {
        this.$refs.batchExport.downloadfile(res)
      })
    },
    /**
     * 刪除
     */
    sysOrgDelete (record) {
      sysOrgDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.getOrgTree()
          this.$refs.table.clearRefreshSelected()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleClick (e) {
      this.queryParam = {
        pid: e.toString()
      }
      this.$refs.table.refresh(true)
    },
    handleOk () {
      this.getOrgTree()
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
